// `sage preset` installed this file automatically.
// Running `sage preset` again could result in automatic deletion of this file.
// Because of this, we do not recommend editing this file.

// ONLY UNCOMMENT WHAT YOU NEED

//import 'bootstrap/js/dist/alert';
//import 'bootstrap/js/dist/button';
//import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
//import 'bootstrap/js/dist/dropdown';
//import 'bootstrap/js/dist/modal';
//import 'bootstrap/js/dist/offcanvas';
//import 'bootstrap/js/dist/popover';
//import 'bootstrap/js/dist/scrollspy';
//import 'bootstrap/js/dist/tab';
//import 'bootstrap/js/dist/toast';
//import 'bootstrap/js/dist/tooltip';
