import * as Klaro from 'klaro/dist/klaro-no-translations-no-css';
import LazyLoad from 'vanilla-lazyload';

import Swiper from 'swiper';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';
SwiperCore.use([Pagination, Navigation, Autoplay]);

export default {
    init() {
        // Detect elements coming into view and add class
        var observer;
        const ivElements = document.querySelectorAll('.iv');
        observer = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
                if (entry.intersectionRatio > 0) {
                    entry.target.classList.add('iv-loaded');
                }
            });
        }, { threshold: [0.5] });

        ivElements.forEach(function(image) {
            observer.observe(image);
        });
        // Detect elements coming into view and add class

        const breakpoint = window.matchMedia( '(min-width:992px)' );
        // eslint-disable-next-line no-unused-vars
        let teamMemberSlider;
        let itemListSlider1;
        let itemListSlider2;
        let supaTuffSlider;
        let footerBrandsSlider;

        const breakpointChecker = function() {
            if ( breakpoint.matches === true ) {
               if ( itemListSlider1 !== undefined ) itemListSlider1.destroy( true, true );
               if ( itemListSlider2 !== undefined ) itemListSlider2.destroy( true, true );
               if ( supaTuffSlider !== undefined ) supaTuffSlider.destroy( true, true );
               if ( footerBrandsSlider !== undefined ) footerBrandsSlider.destroy( true, true );
               return;
            } else if ( breakpoint.matches === false ) {
               return enableSwiper();
            }
         };
        // eslint-disable-next-line no-unused-vars
        const testimonialSlider = new Swiper('.testimonials', {
            slidesPerView: 1,
            spaceBetween: 60,
            speed: 400,
            centeredSlides: true,
            autoplay: {
                delay: 4000,
            },
            navigation: {
                nextEl: '.testimonial-button-next',
                prevEl: '.testimonial-button-prev',
            },
            loop: true,
            breakpoints: {
                992: {
                    slidesPerView: 'auto',
                    spaceBetween: 60,
                },
            },
        });

        // eslint-disable-next-line no-unused-vars
        const productItemSlider = new Swiper('.product-header__sub-images', {
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 600,
            loop: true,
            navigation: {
                prevEl: '.custom-swiper-button-prev',
                nextEl: '.custom-swiper-button-next',
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            },
        });

        // eslint-disable-next-line no-unused-vars
        const aboutUsSlider = new Swiper('.full-swiper', {
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 400,
            centeredSlides: true,
            autoplay: {
                delay: 3000,
            },
            navigation: {
                nextEl: '.full-swiper__button-next',
                prevEl: '.full-swiper__button-prev',
            },
            loop: true,
        });

        const enableSwiper = function() {
            itemListSlider1 = new Swiper('#item-list-1', {
                slidesPerView: 1.2,
                spaceBetween: 35,
                speed: 1000,
                hashNavigation: {
                    watchState: true,
                },
                pagination: {
                    el: '#list-1-pag',
                    clickable: true,
                    type: 'custom',
                    renderCustom: function (swiper, current, total) {
                        var out = ''
                        for (let i = 1; i < total+1; i++) {
                          if (i == current) {
                            out = out + '<span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex='+i+' role="button" aria-label="Go to slide '+i+1+'"></span>';
                          }
                          else {
                            out = out + '<span class="swiper-pagination-bullet" tabindex='+i+' role="button" aria-label="Go to slide '+i+1+'"></span>';
                          }
                        }
                        return out;
                    },
                },
                breakpoints: {
                    600: {
                        slidesPerView: 1.5,
                        spaceBetween: 60,
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 60,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 120,
                    },
                },
            });

            itemListSlider2 = new Swiper('#item-list-2', {
                slidesPerView: 1.2,
                spaceBetween: 35,
                speed: 1000,
                hashNavigation: {
                    watchState: true,
                },
                pagination: {
                    el: '#list-2-pag',
                    clickable: true,
                    type: 'custom',
                    renderCustom: function (swiper, current, total) {
                        var out = ''
                        for (let j = 1; j < total+1; j++) {
                          if (j == current) {
                            out = out + '<span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex='+j+' role="button" aria-label="Go to slide '+j+1+'"></span>';
                          }
                          else {
                            out = out + '<span class="swiper-pagination-bullet" tabindex='+j+' role="button" aria-label="Go to slide '+j+1+'"></span>';
                          }
                        }
                        return out;
                    },
                },
                breakpoints: {
                    600: {
                        slidesPerView: 1.5,
                        spaceBetween: 60,
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 60,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 120,
                    },
                },
            });


            supaTuffSlider = new Swiper('#supaTuffSlider', {
                slidesPerView: 1.2,
                spaceBetween: 35,
                speed: 1000,
                hashNavigation: {
                    watchState: true,
                },
                pagination: {
                    el: '#supaTuffSlider-pag',
                    clickable: true,
                    type: 'custom',
                    renderCustom: function (swiper, current, total) {
                        var out = ''
                        for (let j = 1; j < total+1; j++) {
                          if (j == current) {
                            out = out + '<span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex='+j+' role="button" aria-label="Go to slide '+j+1+'"></span>';
                          }
                          else {
                            out = out + '<span class="swiper-pagination-bullet" tabindex='+j+' role="button" aria-label="Go to slide '+j+1+'"></span>';
                          }
                        }
                        return out;
                    },
                },
                breakpoints: {
                    600: {
                        slidesPerView: 1.5,
                        spaceBetween: 60,
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 60,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 120,
                    },
                },
            });

            teamMemberSlider = new Swiper('.team-members__items', {
                slidesPerView: 1,
                spaceBetween: 60,
                speed: 600,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                },
            });

            footerBrandsSlider = new Swiper('.footer-brands__items', {
                slidesPerView: 1,
                spaceBetween: 20,
                speed: 400,
                centeredSlides: true,
                pagination: {
                    el: '.footer-brands__pagination',
                    clickable: true,
                    type: 'custom',
                    renderCustom: function (swiper, current, total) {
                        var out = ''
                        for (let j = 1; j < total+1; j++) {
                          if (j == current) {
                            out = out + '<span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex='+j+' role="button" aria-label="Go to slide '+j+1+'"></span>';
                          }
                          else {
                            out = out + '<span class="swiper-pagination-bullet" tabindex='+j+' role="button" aria-label="Go to slide '+j+1+'"></span>';
                          }
                        }
                        return out;
                    },
                },
                autoplay: {
                    delay: 3000,
                },
                loop: true,
                breakpoints: {
                    768: {
                        slidesPerView: 3,
                    },
                },
            });
        };

        const handleLoadMore = function(selector) {
            $(selector).slice(0, 6).show(function(){
                if ($(selector + ':hidden').length <= 0) {
                    $(selector).parent().siblings('.js-load-more').hide();
                }
            });
            $(selector).parent().siblings('.js-load-more').on('click', function (e) {
                e.preventDefault();
                $(this).siblings('ul').find('li:hidden').slice(0, 5).fadeIn();
                if ( $(this).siblings('ul').find('li:hidden').length <= 0) {
                    $(this).hide();
                }
            });
        }

        handleLoadMore('.js-product-filter-list li');
        handleLoadMore('.js-category-filter-list li');

        breakpoint.addListener(breakpointChecker);

        breakpointChecker();

        $(window).resize(function() {
            breakpointChecker();
        });

        new LazyLoad({
            elements_selector: '.lazyload',
        });

        // JavaScript to be fired on all pages
        window.klaro = Klaro;
        // we set up Klaro with the config
        Klaro.setup(window.klaroConfig);

        // Opening the mobile menu
        $('#menu-button').on('click', function () {
            $('#menu-button').toggleClass('open');
            $( 'body' ).toggleClass( 'mobile-menu-open' );

            var text = $('#menu-button').attr('aria-label');
            if(text == 'Open mobile navigation menu') {
                $('#menu-button').attr('aria-label', 'Close mobile navigation menu')
                $('#menu-button').attr('aria-expanded', true)
            } else {
                $('#menu-button').attr('aria-label', 'Open mobile navigation menu')
                $('#menu-button').attr('aria-expanded', false)
            }
        });
        // Opening the mobile menu

        $('nav .menu-item-has-children > a').on('click', function (event) {
            event.preventDefault();
            if( $(this).siblings('ul.sub-menu').find('.mobmenu-back').length === 0 ) {
                $(this).siblings('ul.sub-menu').prepend('<span class="mobmenu-back">Back a Level</span>');
            }
            $(this).parent().toggleClass('show');
            $(this).next().toggleClass('show');
        });

        $('nav').on('click', '.mobmenu-back', function () {
            console.log('test');
            $(this).parent().toggleClass('show');
            $(this).next().toggleClass('show');

            $(this).closest('a.dropdown-toggle').toggleClass('show');
            $(this).closest('ul.dropdown-menu').toggleClass('show');
        });
        // Opening the header menu dropdowns

        $(document).scroll(function() {
            var scrollPosition = $(this).scrollTop();

            if (scrollPosition > 150) {
                $('.header--fixed').addClass('open');
            } else {
                $('.header--fixed').removeClass('open');
            }
        });

        document.querySelector('.js-search-toggle').addEventListener('click', () => {
            document.querySelector('.header-search-form').classList.toggle('active');
            document.querySelector('.header-search-form input[type="search"]').focus();
        })

        var productListoptions = {
            valueNames: [ 'name', 'categories', 'industries' ],
        };

        /* global List */
        var productList = new List('product-list', productListoptions);
        let activeFilters = [];
        let activeIndFilters = [];

        if($('.product-search__search').length > 0) {

            const splitString = window.location.href.split('/?s=')[1];
            let searchTerm = '';
            const searchField = document.getElementById('product-search-input');

            if(splitString) {
                searchTerm = splitString.split('&')[0].replace(/\+/g, ' ');
            }

            searchField.value = searchTerm;
            productList.search(searchTerm);

            $('.js-product-filter-list li input').each(function() {
                $(this).prop('checked', true);
                let isChecked = this.checked;
                let filterValue = $(this).parent().attr('data-category');

                if(isChecked) {
                    activeFilters.push(filterValue);
                } else {
                    activeFilters.splice(activeFilters.indexOf(filterValue), 1);
                }

                productList.filter(function (item) {
                    if(activeFilters.length > 0)
                    {
                        var itemCategories = item.values().categories.split(' ');
                        let doShow = false;

                        for(let i = 0; i < activeFilters.length; i++) {
                            if(itemCategories.includes(activeFilters[i])) {
                                doShow = true;
                            }
                        }


                        return doShow;
                    }


                    return true;
                });

                $(this).siblings('.product-search__filter-count').text('(' + productList.matchingItems.length + ')');
                $(this).prop('checked', false);

                activeFilters = [];
            });

            $('.js-category-filter-list li input').each(function() {
                $(this).prop('checked', true);
                let isChecked = this.checked;
                let filterValue = $(this).parent().attr('data-ind-category');

                if(isChecked) {
                    activeIndFilters.push(filterValue);
                } else {
                    activeIndFilters.splice(activeIndFilters.indexOf(filterValue), 1);
                }

                productList.filter(function (item) {

                    if(activeIndFilters.length > 0)
                    {
                        var itemIndustries = item.values().industries.split(' ');
                        let doShow = false;

                        for(let i = 0; i < activeIndFilters.length; i++) {
                            if(itemIndustries.includes(activeIndFilters[i])) {
                                doShow = true;
                            }
                        }


                        return doShow;
                    }



                    // if(activeFilters.length > 0)
                    // {
                    //     var itemCategories = item.values().categories.split(' ');
                    //     var itemIndustries = item.values().industries.split(' ');
                    //     let doShow = false;

                    //     for(let i = 0; i < activeFilters.length; i++) {
                    //         if(itemCategories.includes(activeFilters[i])) {
                    //             if(activeIndFilters.length > 0) {
                    //                 for(let i = 0; i < activeIndFilters.length; i++) {
                    //                     if(itemIndustries.includes(activeIndFilters[i])) {
                    //                         doShow = true;
                    //                     }
                    //                 }
                    //             }
                    //             else {
                    //                 doShow = true;
                    //             }

                    //         }
                    //     }

                    //     return doShow;
                    // }


                    return true;
                });

                $(this).siblings('.product-search__filter-count').text('(' + productList.matchingItems.length + ')');
                $(this).prop('checked', false);

                activeIndFilters = [];
            });

            productList.filter();
        }

        if($('.js-product-filter-list')) {
            $('.js-product-filter-list li input').on('change', function() {
                let isChecked = this.checked;
                let filterValue = $(this).parent().attr('data-category');

                if(isChecked) {
                    activeFilters.push(filterValue);
                } else {
                    activeFilters.splice(activeFilters.indexOf(filterValue), 1);
                }

                productList.filter(function (item) {
                    if(activeFilters.length > 0)
                    {
                        var itemCategories = item.values().categories.split(' ');
                        var itemIndustries = item.values().industries.split(' ');
                        let doShow = false;

                        for(let i = 0; i < activeFilters.length; i++) {
                            if(itemCategories.includes(activeFilters[i])) {
                                console.log(itemIndustries);
                                if(activeIndFilters.length > 0) {
                                    for(let i = 0; i < activeIndFilters.length; i++) {
                                        if(itemIndustries.includes(activeIndFilters[i])) {
                                            doShow = true;
                                        }
                                    }
                                }
                                else {
                                    doShow = true;
                                }

                            }
                        }

                        return doShow;
                    }
                    return true
                });
            });
        }


        if($('.js-category-filter-list')) {
            $('.js-category-filter-list li input').on('change', function() {
                let isChecked = this.checked;
                let filterValue = $(this).parent().attr('data-ind-category');

                if(isChecked) {
                    activeIndFilters.push(filterValue);
                } else {
                    activeIndFilters.splice(activeIndFilters.indexOf(filterValue), 1);
                }


                productList.filter(function (item) {
                    if(activeFilters.length > 0)
                    {
                        var itemCategories = item.values().categories.split(' ');
                        var itemIndustries = item.values().industries.split(' ');
                        let doShow = false;

                        for(let i = 0; i < activeFilters.length; i++) {
                            if(itemCategories.includes(activeFilters[i])) {
                                if(activeIndFilters.length > 0) {
                                    for(let i = 0; i < activeIndFilters.length; i++) {
                                        if(itemIndustries.includes(activeIndFilters[i])) {
                                            doShow = true;
                                        }
                                    }
                                }
                                else {
                                    doShow = true;
                                }
                            }
                        }

                        return doShow;
                    } else {
                        let doShow = false;
                        let itemIndustries = item.values().industries.split(' ');
                        if(activeIndFilters.every(v => itemIndustries.includes(v))) {
                            doShow = true;
                        }
                        return doShow;
                    }
                });
            });
        }


        const imageSwap = document.querySelectorAll('.image-compare');

        if(imageSwap.length > 0) {
            imageSwap.forEach(img => {
                var slider = img.querySelector('.slider');
                var foregroundImg = img.querySelector('.foreground-img');
                var sliderBtn = img.querySelector('.slider-button');

                img.querySelector('.background-img').style.backgroundSize = slider.offsetWidth + 'px';
                img.querySelector('.foreground-img').style.backgroundSize = slider.offsetWidth + 'px';

                var update = (e) => {
                    const sliderPos = e.target.value;
                    foregroundImg.style.width = sliderPos+'%';
                    sliderBtn.style.left = sliderPos+'%' ;
                };

                slider.addEventListener('change', update);
                slider.addEventListener('input', update);
            });
        }


        const faqs = document.querySelectorAll('.faqs__item');

        if(faqs.length > 0) {
            faqs.forEach(item => {

                var title = item.querySelector('.faq-title');
                title.addEventListener('click', () => {
                    if(item.classList.contains('active')) {
                        item.classList.remove('active')
                    } else {
                        item.classList.add('active')
                    }
                })
            });
        }





        function checkAccordions() {
            var windowWidth = $(window).width();
            if(windowWidth < 992) {
                $('.accordion-button').addClass('collapsed');
                $('.accordion-collapse').removeClass('show');
            }
        }

        $(document).ready(function(){
            checkAccordions();
        });

        $(window).resize(function() {
            checkAccordions();
        });

        $(window).on('resize', function() {
            var headerHeight = $('header.header').outerHeight();
            $('.header-spacer').css('height', headerHeight+'px');
        });
        $(window).trigger('resize');

        $('.js-enquire').on('click', function() {
            $('.enquire-modal').addClass('open');
        });

        $('#enquire-close').on('click', function() {
            $('.enquire-modal').removeClass('open');
        });

        if(window.location.hash && $('body.blog')) {
            $('.product-search__filter-list li').each(function() {
                const filterCategory = $(this).data('category');
                const filterIndCategory = $(this).data('ind-category');
                const chosenCategory = window.location.hash.replace('#', '');
                if(filterCategory == chosenCategory) {
                    $(this).find('label').click();
                }
                if(filterIndCategory == chosenCategory) {
                    $(this).find('label').click();
                }
            })
        }

        $('.js-product-sub-image').on('click', function() {
            let backgroundImage = $(this).css('background-image');

            if($(this).hasClass('product-header__sub-image--video')) {
                let videoSrc = $(this).data('videosrc');
                $('.product-header__main-image-wrapper').html(`
                    <video width="100%" controls class="product-header__main-video">
                        <source src="" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>

                `);
                $('.product-header__main-video source').attr('src', videoSrc);
                $('.product-header__main-video')[0].load()
            } else {
                $('.product-header__main-image-wrapper').html(`
                    <div class="product-header__main-image"></div>
                `);
                $('.product-header__main-image').css('background-image', backgroundImage);
            }
        });

        $('.form--search label svg').on('click', function() {
            //$(this).closest('form').submit();
            $(this).siblings('.form-control').toggleClass('show');
        });

        $('#mc-embedded-subscribe').on('click', function() {
            $('.footer__sub-confirmation').fadeIn();
        });

        let $headerSearchForm               = $('form.form.form--search.search-form');
        let headerSearchFormTimeout         = null;
        let headerSearchFormAutocompleting  = false;

        function headerSearchFormAutocomplete($form, $input)
        {
            if (
                !($form instanceof jQuery) || $form.length != 1
                || !($input instanceof jQuery) || $input.length != 1
            ) {
                return;
            }

            let query = $input.val();
            if (typeof query != 'string' || (query = query.trim()).length < 2) {
                return;
            }

            let url = $form.attr('data-autocomplete-action');
            if (typeof url != 'string' || (url = url.trim()).length < 1) {
                return;
            }

            if (headerSearchFormAutocompleting) {
                return false;
            }

            /* eslint-disable */
            $.ajax({
                url: url,
                data: $form.serialize(),
                type: 'GET',
                beforeSend: function (xhr) {

                    headerSearchFormAutocompleting = true;

                },
                success: function (data, textStatus, xhr) {

                    let $results = $(data);
                    if ($results.length < 1) {
                        return;
                    }

                    $results.insertAfter($form);

                },
                error: function (xhr, textStatus, errorThrown) {

                },
                complete: function (xhr, textStatus) {

                    headerSearchFormAutocompleting = false;

                },
            });

            headerSearchFormTimeout = null;
            /* eslint-enable */
        }

        $headerSearchForm.on('input change keypress', 'input[type="search"]', function (e) {



            let $headerSearchInput = $(e.target);
            if ($headerSearchInput.length != 1) {
                return;
            }

            let $headerSearchFormUsed = $(e.delegateTarget);
            if ($headerSearchFormUsed.length != 1) {
                return;
            }

            if (headerSearchFormTimeout) {
                window.clearTimeout(headerSearchFormTimeout);
            }

            headerSearchFormTimeout = window.setTimeout(function () {
                $('#products-predictive').remove();
                headerSearchFormAutocomplete($headerSearchFormUsed, $headerSearchInput);
            }, 500);

        });

        const videos = document.querySelectorAll('.video__wrapper');

        if (videos.length > 0) {
            videos.forEach(v => {
                const video = v.querySelector('.page-video');
                const videoPoster = video.nextElementSibling;

                videoPoster.addEventListener('click', () => {
                    if (!video.paused) {
                        window.videoPlaying = false;
                        return;
                    }
                    video.setAttribute('controls', true);
                    videoPoster.classList.add('hide-poster');

                    setTimeout(function() {
                        video.play();
                        window.videoPlaying = true;
                    }, 100)
                });

                video.addEventListener('click', () => {
                    if (!video.paused) {
                        window.videoPlaying = false;
                        return;
                    }

                    video.setAttribute('controls', true);
                    videoPoster.classList.add('hide-poster');

                    setTimeout(function() {
                        video.play();
                        window.videoPlaying = true;
                    }, 100);
                });
            });
        }


        const lazyVideos = document.querySelectorAll('video.image-hero__video-home');


        var videoObserver;
        videoObserver = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
                if (entry.intersectionRatio > 0) {
                    const videoElement = entry.target;
                    if (videoElement.getAttribute('data-loaded') !== 'true') {
                        videoElement.innerHTML = `
                            <source src="${videoElement.getAttribute('data-src')}" type="video/mp4">
                            Your browser does not support the video tag.
                        `;
                        videoElement.load();
                        videoElement.setAttribute('data-loaded', 'true');
                    }
                }
            });
        }, { threshold: [0.5] });

        lazyVideos.forEach(function(video) {
            videoObserver.observe(video);
        });


    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
